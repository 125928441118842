//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardBody } from 'bootstrap-vue';
import { Icon } from '@iconify/vue2';

export default {
    name: "CourseCardSummary",
    components: { BCard, BCardBody, Icon },
    props: {
        includedMaterials: Array,
        requirements: Array,
        instructors: Array,
        instructorsGender: String,
        category: String,
        also: Array,
        coursePack: Boolean,
    }
};
