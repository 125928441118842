import { render, staticRenderFns } from "./CourseCardModules.vue?vue&type=template&id=375cf652&scoped=true"
import script from "./CourseCardModules.vue?vue&type=script&lang=js"
export * from "./CourseCardModules.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "375cf652",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ListIcon: require('/builds/marketing/abstracta.academy-website/components/ListIcon.vue').default,Accordion: require('/builds/marketing/abstracta.academy-website/components/Accordion.vue').default})
