//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SectionClientSlider",
  props: {
    title: String,
    logos: Array
  },
  data() {
    return {
      logosList: [
        {
          url: require("~/assets/img/logo-casa-de-la-mujer-union.png?webp"),
          alt: "Logo de Casa de la Mujer"
        },
        {
          url: require("~/assets/img/logo-fe-y-alegria.png?webp"),
          alt: "Logo de Fe y Alegría"
        },
        {
          url: require("~/assets/img/logo-fundacion-emplea.png?webp"),
          alt: "Logo de Fundacion Emplea"
        },
        {
          url: require("~/assets/img/logo-inefop.png?webp"),
          alt: "Logo de INEFOP"
        },
        {
          url: require("~/assets/img/logo-jovenes-a-programar.png?webp"),
          alt: "Logo de Jóvenes a Programar"
        },
        {
          url: require("~/assets/img/logo-pyxis-50.png?webp"),
          alt: "Logo de Pyxis programa 50"
        },
        {
          url: require("~/assets/img/logo-reconvertite.png?webp"),
          alt: "Logo de ReconverTIte",
          site: "https://reconvertite.uy/"
        },
        {
          url: require("~/assets/img/logo-upcamp.png?webp"),
          alt: "Logo de UpCamp"
        },
      ]
    }
  }
};
