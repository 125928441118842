//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardBody } from 'bootstrap-vue';
import { Icon } from '@iconify/vue2';

export default {
    name: "CourseCardModules",
    components: { BCard, BCardBody, Icon },
    props: {
        courseModules: Array,
        coursePack: Boolean,
        whatWillLearnIntro: String,
    }
};
