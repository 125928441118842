//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardText } from 'bootstrap-vue';
import { Icon } from '@iconify/vue2';

export default {
  name: "Instructores",
  layout: "default",
  head() {
    return {
      title: 'Instructores',
      meta: [
        { hid: 'description', name: 'description', content: 'Nuestros instructores son mentores ¡Conócelos!' },
        { hid: 'keywords', name: 'keywords', content: 'abstracta academy instructores' },
        { hid: 'og:image', property: 'og:image', content: require('~/assets/img/abstracta-academy-meta-data.jpg') },
      ]
    }
  },
  components: { BCard, BCardText, Icon },
  data() {
    return {
      testimonyList: [
        {
          name: "Carolina Matonte",
          position: "QE Lead en Abstracta, Instructora en Abstracta Academy",
          text: `"Cuando compartimos conocimiento, más aprendemos. Ser instructora en Abstracta Academy, me lo confirma cada día. Es un gran desafío, no es solo enseñar; sino que, además, implica guiar y motivar a los estudiantes para que también se apasionen sobre lo que están aprendiendo, investiguen, cuestionen. Es crecimiento profesional y personal continuo".`,
          photo: require("~/assets/img/testimony-photo-carolina-matonte.png?webp"),
          iconCountry: require("~/assets/img/icon-flag-uruguay.svg"),
        },
        {
          name: "Mikaella Mateos",
          position: "Jr Adv Performance Tester - Abstracta",
          text: `"Ser instructora en Abstracta Academy es algo que valoro mucho por las oportunidades que me ha dado. Me ha permitido desarrollar un crecimiento continuo; ya que al compartir conocimiento aprendemos más. Así como incentivar a otras personas a incursionar y crecer en el mundo del testing".`,
          photo: require("~/assets/img/testimony-photo-mikaella-mateos.png?webp"),
          iconCountry: require("~/assets/img/icon-flag-uruguay.svg"),
        },
        {
          name: "Leticia Rojí",
          position: "Instructora en Abstracta Academy",
          text: `"Ser instructora en Abstracta Academy es una experiencia que me enriquece desde lo profesional y lo personal. La dinámica acompañamiento de los estudiantes en su proceso de aprendizaje es de retroalimentación, aprendemos enseñando y cada estudiante representa para mí una nueva oportunidad de crecer en diversas áreas del conocimiento de las TICs".`,
          photo: require("~/assets/img/testimony-photo-leticia-roji.png?webp"),
          iconCountry: require("~/assets/img/icon-flag-uruguay.svg"),
        },
        {
          name: "Renzo Parente",
          position: "QE Lead en Abstracta",
          text: `"Me encanta poder aportar un grano de arena en el crecimiento profesional de las personas desde la enseñanza. En Academy no solo tengo esa oportunidad sino que también tengo oportunidad de crecer personalmente trabajando codo a codo con un equipo que apuesta a mejorar la plataforma".`,
          photo: require("~/assets/img/testimony-photo-renzo-parente.png?webp"),
          iconCountry: require("~/assets/img/icon-flag-uruguay.svg"),
        }
      ],
      instructorsList: [
        {
          name: "Andréi Guchin",
          linkedInUrl: "https://www.linkedin.com/in/andrei-guchin",
          tags: ["Taurus para pruebas de performance"],
          description: "Ingeniero Eléctrico en Telecomunicaciones egresado de la Universidad de la República. Trabaja en Abstracta desde el 2011 y cuenta con más de 9 años de experiencia en el área de ingeniería de performance. También cuenta con experiencia en proyectos de procesamiento de imágenes en el área biomédica.",
          img: require("~/assets/img/instructor-andrei-guchin.png?webp")
        },
        {
          name: "Axel Arzuaga",
          linkedInUrl: "https://www.linkedin.com/in/axel-arzuaga-8755bb183/",
          tags: ["Selenium - Java"],
          description: "Tecnólogo en Informática recibido en la Universidad de la República en 2018. Trabaja en Abstracta desde 2019 habiéndose especializado en el área de QA Automation. Su experiencia radica principalmente en pruebas automatizadas a nivel frontend en sistemas web, mobile y desktop usando múltiples lenguajes de programación. También ha participado en desarrollo de pruebas automáticas a nivel de API.",
          img: require("~/assets/img/instructor-axel-arzuaga.png?webp")
        },
        {
          name: "Carla Pérez",
          linkedInUrl: "https://www.linkedin.com/in/carla-perez-imbert/",
          tags: ["Introducción a SoapUI con Groovy"],
          description: "Estudiante de Licenciatura en Tecnologías de la Información y Full Stack Web Developer. Tester de Software egresada de Abstracta Academy. Trabaja en Abstracta desde febrero de 2022, realizando tareas de testing funcional y automatización de pruebas de software.",
          img: require("~/assets/img/instructor-carla-perez.png?webp")
        },
        {
          name: "Carmen Rivas",
          linkedInUrl: "https://uy.linkedin.com/in/carmen-rivas-252218236",
          tags: ["Pruebas de accesibilidad web y móvil", "Software Tester", "OrgaNICE!", "Técnicas de caja blanca", "Técnicas de caja negra", "Mobile Testing", "Introducción a la Automatización", "JMeter + BlazeMeter", "Introduccion a API Testing", "Introducción a Postman", "Testing Exploratorio"],
          description: "Licenciada en Educación con Mención en Castellano y Literatura. Desde 2020, inició su reconversión al mundo TI. Es Tester de Software formada en Abstracta Academy, y cuenta con la certificación como Scrum Master. En julio de 2022 se sumó al equipo de Abstracta Academy en la búsqueda de oportunidades para aportar con conocimientos y experiencias, y de igual modo aprovechar las situaciones que le brinden nuevos aprendizajes.",
          img: require("~/assets/img/instructor-carmen-rivas.png?webp")
        },
        {
          name: "Carolina Matonte",
          linkedInUrl: "https://www.linkedin.com/in/carolina-matonte-pereira/",
          tags: ["Pruebas de accesibilidad web y móvil", "Software Tester", "Técnicas de caja blanca", "Técnicas de caja negra"],
          description: "Analista de sistemas en computación, egresada de la Universidad ORT del Uruguay. Especializada en testing; desde el 2005 se encuentra en formación continua en esta área. Su foco está en el área de testing, en educación e-learning y gestión remota.",
          img: require("~/assets/img/instructor-carolina-matonte.png?webp")
        },
        {
          name: "Laura Gayo",
          linkedInUrl: "https://www.linkedin.com/in/marialauragayo",
          tags: ["Selenium - Java", "WebdriverIO - JavaScript"],
          description: "Licenciada en Tecnologías de la información, con 5 años de experiencia en el área. Cuenta con experiencia en testing funcional y automatización. <br/><br/>Forma parte de Abstracta desde Enero de 2019.",
          img: require("~/assets/img/instructor-laura-gayo.png?webp")
        },
        {
          name: "Leticia Rojí",
          linkedInUrl: "https://www.linkedin.com/in/leticia-roj%C3%AD-477b2421b/",
          tags: ["Pruebas de accesibilidad web y móvil", "Software Tester", "OrgaNICE!", "Técnicas de caja negra", "Mobile Testing"],
          description: "Tester de Software egresada de Abstracta Academy, con 5 años de experiencia docente como profesora de Física y actualmente estudiante de la Licenciatura de Enseñanza de las Ciencias en UNSAM. <br/><br/>En el 2020 se sumó al equipo de Abstracta Academy como instructora del curso de Software Tester. ",
          img: require("~/assets/img/instructor-leticia-roji.png?webp")
        },
        {
          name: "Mariana Falabrino",
          linkedInUrl: "https://www.linkedin.com/in/mariana-falabrino/",
          tags: ["GXtest"],
          description: "Desde el 2010 trabaja en las ciencias de las computación como desarrolladora GeneXus, tester funcional y soporte de clientes en diferentes plataformas. Actualmente forma parte del equipo de GXtest como Customer Success, la herramienta de automatización de GeneXus, sugiriendo mejoras y nuevas funcionalidades basadas en las necesidades de los clientes.",
          img: require("~/assets/img/instructor-mariana-falabrino.png?webp")
        },
        {
          name: "Matías Fornara",
          linkedInUrl: "https://www.linkedin.com/in/matias-fornara-ernst/",
          tags: ["Selenium - Java", "Introducción a la Automatización"],
          description: "Técnico superior en informática. Tiene más de siete años de experiencia en tecnología, trabaja en Abstracta desde 2017, y cuenta con experiencia en desarrollo, automatización, pruebas de performance y Scrum. También se ha desempeñado como instructor de automatización de pruebas funcionales en el instituto BIOS en Uruguay.",
          img: require("~/assets/img/instructor-matias-fornara.png?webp")
        },
        {
          name: "Maximiliano Sosa",
          linkedInUrl: "https://www.linkedin.com/in/maximiliano-sosa-640b8ab0/",
          tags: ["Mobile Testing"],
          description: "Analista de Sistemas egresado de Círculo Informático, con 10 años de experiencia en el rubro como Tester de Software, especializado principalmente en el área funcional, aplicaciones web y mobile. Trabaja en Abstracta desde el 2021 y a través de Academy ha ido expandiendo y perfeccionando sus conocimientos en diferentes áreas sobre testing.",
          img: require("~/assets/img/instructor-maximiliano-sosa.png?webp")
        },
        {
          name: "Renzo Parente",
          linkedInUrl: "https://www.linkedin.com/in/codigoenorbita/",
          tags: ["Introduccion a API Testing", "Introducción a Postman"],
          description: "QE Lead con 6 años de experiencia en testing funcional y automatizado para web y servicios Rest. Tiene experiencia trabajando en metodologías ágiles y ha participado en múltiples proyectos de Gobierno Digital, eCommerce y Post de Pagos, Plataforma de IOT, Wallet Digital. Forma parte de Abstracta desde Octubre de 2018.",
          img: require("~/assets/img/instructor-renzo-parente.png?webp")
        },
        {
          name: "Rocío Cruz",
          linkedInUrl: "http://www.linkedin.com/in/rociocruzsanchz/",
          tags: ["Introducción a SoapUI con Groovy"],
          description: "Ingeniera informática con más de 10 años de experiencia en el ciclo de desarrollo de software. Tiene experiencia trabajando en testing funcional y automatización. Forma parte de Abstracta desde diciembre de 2021.",
          img: require("~/assets/img/instructor-rocio-cruz.png?webp")
        },
        {
          name: "Santiago Rodríguez",
          linkedInUrl: "https://uy.linkedin.com/in/santiago-rodriguez-262a5115a",
          tags: ["Introduccion a API Testing"],
          description: "Estudiante de Analista en Tecnologías de la Información en la Universidad ORT. Trabaja en Abstracta desde septiembre de 2021 y cuenta con más de 4 años de experiencia en testing funcional y automatizado.",
          img: require("~/assets/img/instructor-santiago-rodriguez.png?webp")
        },
        {
          name: "Sebastián Lorenzo",
          linkedInUrl: "https://www.linkedin.com/in/sebastianlorenzo88/",
          tags: ["JMeter + BlazeMeter"],
          description: "Ingeniero informático de UdelaR, con más de siete años de experiencia trabajando en Abstracta, principalmente en el área de ingeniería de performance. En los últimos años, ha participado en varios proyectos en esta área, desde el monitoreo de sistemas hasta el desarrollo de herramientas para pruebas y diagnóstico de problemas. Durante los últimos tres años, se ha desempeñado como profesor en la Universidad de la República (Uruguay) donde es tutor de taller de sistemas de información geográficos empresariales.",
          img: require("~/assets/img/instructor-sebastian-lorenzo.png?webp")
        }
      ]
    };
  },
  methods: {

  }
};
