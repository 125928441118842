//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { BCard, BCardText, BCardHeader, BCardBody, BCollapse, BInputGroup, BFormInput, BForm } from 'bootstrap-vue';
import { Icon } from '@iconify/vue2';
import stringSimilarity from 'string-similarity';
;

var formatter = new Intl.NumberFormat({
  style: 'currency',
  currency: 'UYU',
  currencyDisplay: "code",
  minimumFractionDigits: 0,
});

export default {
  name: "Instructores",
  layout: "default",
  components: { BCard, BCardText, BCardHeader, BCardBody, BCollapse, BInputGroup, BFormInput, BForm, Icon },
  head() {
    return {
      title: 'Concretar compra',
      meta: [
        { hid: 'description', name: 'description', content: '¿Cómo funcionan los cursos en Abstracta Academy? ¿Todos los curso son relacionados a testing de software? Respondemos tus preguntas aquí.' },
        { hid: 'keywords', name: 'keywords', content: 'abstracta academy concretar compra' },
        { hid: 'og:image', property: 'og:image', content: require('~/assets/img/abstracta-academy-meta-data.jpg') },
      ]
    }
  },
  data() {
    return {
      courseName: "",
      courseBadgeImg: "",
      searchValue: "",
      coursePriceUSD: "",
      coursePricePesos: "",
      coursePricePesosSinIVA: "",
      coursePricePesos10OFF: "",
      coursePaymentMethod: "",
      courseTalentLink: "",
      faqList: [
        {
          question: "Es mi primera vez, no tengo cuenta en Abstracta Academy",
          response: `Al hacer clic en <b>Registro</b> te llevará a una pantalla para cargar tus datos y crear tu cuenta. <br />
                    Automáticamente te llegará un correo de bienvenida para confirmar tu cuenta <b>(verifica tu bandeja de correo no deseado)</b>. <br /><br />

                    Al hacer clic en <b>Confirmar cuenta</b> te llevará al panel de inicio. <br /><br />

                    En el panel de inicio podrás ver del lado derecho un listado en donde la primera sección es <b>Catálogo de cursos</b>. Para adquirir un curso debes hacer clic allí. <br /><br />

                    Elige el curso y selecciona <b>Comprar este curso</b>. <br /><br />

                    En un paso siguiente te pedirá una confirmación de pagar y te redireccionará al sitio de <b>PayPal</b> para realizar el pago. <br /><br />

                    En el siguiente paso debes hacer clic en <b>Checkout</b>. Te redireccionará a <b>PayPal</b> para que ingreses los datos de tu tarjeta de crédito y finalices la compra. <br /><br />

                    Una vez que concretas la compra, un mensaje automático desde <b style="color: var(--primary);">Abstracta Academy</b> te llegará al correo con el cual te registraste para darte la bienvenida. <br /><br />

                    No olvides revisar con frecuencia la bandeja de correo no deseado ya que a medida que avances en el curso si los correos llegan ahí puedes perderte información importante.<br /><br />

                    Para profundizar en el uso de la plataforma y todas sus funcionalidades podrás acceder a la guía completa que te aparecerá en el <b>Panel de Inicio</b> <span style="color: var(--primary);">Guía para estudiantes Abstracta Academy</span>.`
        },
        {
          question: "Ya tengo cuenta en Abstracta Academy",
          response: `<b>Loguéate con tu usuario y contraseña.</b> <br /><br />

                    En el panel de inicio podrás ver del lado derecho un listado en donde la primera sección es <b>Catálogo de cursos</b>. Para adquirir un curso debes hacer clic allí. <br /><br />

                    Elige el curso y selecciona <b>Comprar este curso.</b> <br /><br />

                    En un paso siguiente te pedirá una confirmación de pagar y te redireccionará al sitio de PayPal para realizar el pago. <br />
                    En el siguiente paso debes hacer clic en <b>Checkout</b>. Te redireccionará a PayPal para que ingreses los datos de tu tarjeta de crédito y finalices la compra. <br /><br />

                    Una vez que concretas la compra, un mensaje automático desde <b style="color: var(--primary);">Abstracta Academy</b> te llegará al correo con el cual te registraste para darte la bienvenida. <br /><br />

                    No olvides revisar con frecuencia la bandeja de correo no deseado ya que a medida que avances en el curso si los correos llegan ahí puedes perderte información importante. <br /><br />

                    Para profundizar en el uso de la plataforma y todas sus funcionalidades podrás acceder a la guía completa que te aparecerá en el <b>Panel de Inicio</b> <span style="color: var(--primary);">Guía para estudiantes Abstracta Academy</span>.`
        }
      ]
    };
  },
  computed: {
    filteredFaqsList() {
      if (this.searchValue.length < 2) return this.faqList;
      const ratings = this.faqList.map((faq) => { return [stringSimilarity.compareTwoStrings(this.searchValue.toLowerCase(), faq.question.toLowerCase()), faq] })
      const minVal = 0.03;
      const results = ratings.filter(([r, faq]) => { return r > minVal }).sort((a, b) => b[0] - a[0]).map(([r, faq]) => faq);
      return results;
    }

  },
  async mounted() {
    this.faqList = this.faqList.map((faq, index) => {
      return { ...faq, id: index }
    });
    sessionStorage.getItem('coursePriceUSD') == null ? this.$router.push('/es/') : '';
    this.courseName = sessionStorage.getItem('courseName');
    this.courseBadgeImg = sessionStorage.getItem('courseBadgeImg');
    this.coursePriceUSD = sessionStorage.getItem('coursePriceUSD');
    this.coursePricePesos = sessionStorage.getItem('coursePricePesos');
    this.coursePaymentMethod = sessionStorage.getItem('coursePaymentMethod');
    this.courseTalentLink = sessionStorage.getItem('courseTalentLink');
    this.coursePricePesosSinIVA = formatter.format(Math.round((this.coursePricePesos - (this.coursePricePesos * 0.2))));
    this.coursePricePesos10OFF = formatter.format((this.coursePricePesos - (this.coursePricePesos * 0.1).toFixed()));
    this.coursePricePesos = formatter.format(this.coursePricePesos);
  },
  methods: {
  },
};
