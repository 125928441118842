//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  BNavbar,
  BNavbarNav,
  BNavItem,
  BCollapse,
  BNavbarBrand,
  BNavbarToggle,
} from "bootstrap-vue";
import { Icon } from '@iconify/vue2';

export default {
  name: "NavBar",
  components: {
    BNavbar,
    BNavbarNav,
    BNavItem,
    BCollapse,
    BNavbarBrand,
    BNavbarToggle,
    Icon
  },
  data() {
    return {
      navStatus: 'collapsed',
      navOpened: false
    };
  },
  computed: {
    navCollapsed() {
      return this.navStatus == 'collapsed';
    },
    navCollapsing() {
      return this.navStatus == 'collapsing';
    },

  },
  methods: {
    closeNav() {
      this.navOpened = false;
    }
  }
};
