import { render, staticRenderFns } from "./_curso.vue?vue&type=template&id=6fd16268&scoped=true"
import script from "./_curso.vue?vue&type=script&lang=js"
export * from "./_curso.vue?vue&type=script&lang=js"
import style0 from "./_curso.vue?vue&type=style&index=0&id=6fd16268&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fd16268",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CourseFloatingCard: require('/builds/marketing/abstracta.academy-website/components/CourseFloatingCard.vue').default,HeaderMain: require('/builds/marketing/abstracta.academy-website/components/HeaderMain.vue').default,CourseCardSummary: require('/builds/marketing/abstracta.academy-website/components/CourseCardSummary.vue').default,CourseCardLearningTopics: require('/builds/marketing/abstracta.academy-website/components/CourseCardLearningTopics.vue').default,CourseCardMaterialsRequirements: require('/builds/marketing/abstracta.academy-website/components/CourseCardMaterialsRequirements.vue').default,CourseCardPreview: require('/builds/marketing/abstracta.academy-website/components/CourseCardPreview.vue').default,CourseCardAboutInstructor: require('/builds/marketing/abstracta.academy-website/components/CourseCardAboutInstructor.vue').default,CourseCardModules: require('/builds/marketing/abstracta.academy-website/components/CourseCardModules.vue').default,CourseCardPhrase: require('/builds/marketing/abstracta.academy-website/components/CourseCardPhrase.vue').default,CourseCardSpecialization: require('/builds/marketing/abstracta.academy-website/components/CourseCardSpecialization.vue').default})
